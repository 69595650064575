@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './custom.css';

/* Additional fallback styles for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(239, 239, 243, 0.12) transparent;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}